//$hero-full-screen-bg: url("../img/header_1.jpg");
$hero-full-screen-bg-size: cover !important;
$hero-full-screen-text-color: $white;

.hero-full-screen {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  background-position: left;
  background-size: $hero-full-screen-bg-size;
  padding: 4rem;

  .hero-header-block-inner{
    margin: auto;
    width: 100%;
  }

  &.foiskola {
//    background: url("../img/header_1.jpg");
    background-position: center;
  }
  &.hallgatoknak{
//  background: url("../img/header_6.jpg");
  background-position: center;
}
  //MA ES HOLNAPI ORAK FELSOROLASANAK DOBOZA START
  &.jogakozpont {
//    background: url("../img/header_3_b.jpg");
    background-position: center;

    .title {
      margin-bottom: 0;
      padding: 0;
      line-height: 2;
    }

    .gomb {
      background: $alert-color;
    }
    .link{
      text-align: center;
    }
  }
  //MA ES HOLNAPI ORAK FELSOROLASANAK DOBOZA END

  @include breakpoint(medium down) {
    padding: 4rem 2rem;
  }
  @include breakpoint(small down) {
    padding: 2rem;
    text-align: center;
  }

  .middle-content-section {
    text-align: left;
    color: $hero-full-screen-text-color;
    @include breakpoint(small down) {
      text-align: center;
    }

    & h1 {
      color: $white;
      font-weight: bold;
      margin-bottom: 3rem;
      line-height: 1.2em !important;

      @include breakpoint(small) {
        //text-align: center;
        margin: 0 auto 1.5rem;
        font-size: 2.5rem;
      }
      @include breakpoint(medium) {
        margin-bottom: 3rem;
        font-size: 3rem;
      }
      @include breakpoint(large) {
      font-size: 4rem;
    }
    }
  }
}
