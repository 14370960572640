.button {
  border-radius: 5px !important;

  &.success {
    color: $white !important;
  }
}

.button {
  &.warning {
    color: $white !important;
  }
}

.button {
  &.white {
    color: $white !important;
    background: transparent;
  }
}

.button {
  &.alert {
    color: $white !important;
  }
}

.button {
  &.info {
    color: $white !important;
    background: $info;
  }
}

.icon {
  font-size: 1.5rem;
}

.tovabb {
  font-size: 0.8rem;
  cursor: pointer;
  color: $white;

  &:hover {
    color: $light-gray;
  }
  font-weight: 800;

  & .icon {
    height: 15px;
    margin: 0 0 2px 3px;
  }
}
