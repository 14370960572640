.block-share-horizontal {
//  background: rgba($dark-gray,0.5);
  font-size: 2rem;
  color:$white;

  & a {
    font-size: 2rem;
    margin-right: 1rem;
  }

}

.block-oszdmeg {
  a {
    color: $medium-gray;
    &:hover,:focus {
      color: $success-color;
    }
  }

  p {
    line-height: 1rem;
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  @include breakpoint(small) {
    text-align: left;
  }
  @include breakpoint(medium) {
    text-align: right;
  }

  ul {
    list-style-type: none;
    font-size: 2rem;
    margin-left:0;
    color: #bbb;
    float: right;
    display: flex;
    justify-content: space-around;
    @include breakpoint(small) {
    float: none;
    }
    @include breakpoint(medium) {
      display: block;
    }

  }
}
