@mixin card-background-blend-mode {
  background-blend-mode: color;
}

.card {
  border-radius: 7px !important;
  @include breakpoint(small down) {
    margin-bottom: 2rem !important;
  }

  .card-section {
    a {
      color: $black;
    }
  }
  // HERO HEADER INFO CARDOK
  &.card-hero {
    @include clearfix;
    color: $white;
    border: none;
    background-color: rgba($success-color, .8);
    @include box-shadow-on;

    & .thumbnail {
      border: none;
      box-shadow: none;
    }

    .calendar_block-day {
      margin-right: 0;
    }

    & .small-text {
      font-size: 1rem !important;
    }

    > h3,
    h4,
    h5 {
      margin-bottom: 0;
      @include breakpoint(small) {
        margin-left: 1rem auto;
        text-align: left;
        font-size: 0.9rem;
      }
    }

    &.alert {
      background-color: rgba($alert-color, .8);
      @include card-background-blend-mode;
    }

    &.secondary {
      background-color: rgba($secondary-color, .8);
      @include card-background-blend-mode;
    }

    .card-divider {
      background: $warning-color;
      padding: 0.5rem 0 0;
    }

    &.orarend {
      background: none !important;

/*      @include breakpoint(small) {
        &.media-object {
          flex-wrap: wrap;

          &.media-object-section {
            margin: auto;
          }
        }
      }
      @include breakpoint(medium) {
          &.media-object-section {
            margin: 0;
        }
      }*/
      .icon {
        line-height: 1;
        height: 17px;
      }

      h4 {
        line-height: unset !important;
        margin-bottom: 5px;
      }

      & .content {
        background: rgba($secondary-color, 0.85);

        > li,
        ul {
          margin-left: 0;
        }
      }

      & .datum {
        font-size: 0.8rem;
        font-weight: 700;
        font-size: 1rem;
      }

      & .oktato {
        list-style-type: none;
        font-size: 0.8rem;
        line-height: 0.8rem;
        margin-bottom: 7px;
      }

      & .nap {
        text-transform: uppercase;
        margin-bottom: 0.5rem;
      }
    }
    /* Felvételi megosztott card info doboz */
    &.datum-info {
      .card-divider {
        background: $secondary-color;
        padding: 1rem;
      }

      .calendar_block-day {
        margin-right: 10px;
      }

      .content {
        .lead {
          font-weight: 700;
          font-size: 1rem;
        }

        ul {
          list-style-position: inside;
          list-style-type: disc !important;
        }

        a {
          font-weight: normal !important;
        }
      }
    }
    @include breakpoint(small) {
      max-width: 100%;
      float: none;
      margin: 2rem auto 0;

      & ul {
        list-style-type: none !important;
        margin-left: 0 !important;
      }
    }
    @include breakpoint(medium) {
      max-width: 240px;
      float: right;
      margin: 2rem auto 0;

      & h4 {
        font-size: 1.2rem;
      }

      & .content ul {
        list-style-position: outside;
        list-style-type: disc; //        margin-left: 0;
        margin-bottom: 0;
      }
    }
    @include breakpoint(large) {
      max-width: 288px;
      float: right;
    }

    a {
      //      text-decoration: underline;
      font-weight: 800;
      color: $white;
    }
  }
  //CARD, AHOL A KEPBEN VAN BENNE A CIM
  &.card-title-in-picture {
    h2 {
      text-align: center;
    }

    .card-image {
      background-size: cover !important;
      background-position: center !important;
      max-height: none !important;
      display: block;

      &-heading {
        min-height: 200px;
        max-height: 200px;
        display: flex;
        flex-flow: column-reverse;
      }
      /*
      &.elso {
        background: url("/assets/img/dummy11.jpg");
      }

      &.masodik {
        background: url("/assets/img/dummy5.jpg");
      }

      &.harmadik {
        background: url("/assets/img/dummy8.jpg");
      }

      &.negyedik {
        background: url("/assets/img/dummy4.jpg");
      }
*/
      .heading-primary {
        @include background-gradient-bw;
        margin-bottom: 0;
        padding: 100px 5px 5px;
        text-align: center;

        a {
          color: $white;
        }
      }
    }
  }

  &.card-title-content-in-picture {
    position: relative;
    z-index: 1;
    color: $white;
    border: none;
    //    padding: 1rem;
    & .card-image {
      display: flex;
      flex-direction: column;
      flex-grow: inherit; //      background-image: url(/assets/img/bhf-feature-2.jpg);
      background-color: $info-color;
      background-size: cover;
    }

    & .card-section,
    .card-divider {
      background: transparent;
      border: none;
    }

    & a {
      color: $white;
    }
    /*    &::before {
      padding: 10px;
      color: #795548;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: -1;
   background: url("/assets/img/dummy3.jpg");
      background-blend-mode: color-burn;
      background-color: $info-color;
      background-size: cover;
    }*/
  }
  // INFO CARD KÉPPEL
  &.info-card {
    border: none;
    background: none;

    & h4 {
      color: $white;
    }

    .card-section {
      background: $white;
      border-bottom: solid 4px $primary-color;
    }

    &.card-image {
      max-height: 148px;
      overflow: hidden;
      display: flex;

      > .photo {
        min-width: 100%;
      }

      .card-image-heading {
        min-height: 60px;
        display: flex;
        flex-flow: column-reverse;
      }
    }

    .card-divider {
      padding: 0;
      background: none;

      & h4 {
        font-weight: $font-extra-bold;
        color: $primary-color;
      }
    }
  }

  .card-section > ul {
    @include no-bulleted-list;
  }
  /*  &.card-simple-text {
    background: none;
    border: none !important;
    padding: 0;

    .card-section {
      padding: 0;

      .text {
        padding: 15px 0;
        border: solid $medium-gray;
        border-width: 0 0 0.01rem 0;

        a {
          color: $black;
        }
      }
    }

    > :first-child {
      border-bottom: 0.01rem solid $medium-gray;
    }
  } */
  //JELENTKEZESI CARD START
  &.card-oktato {
    .lead,
    h4 {
      margin: 0;
      padding: 0;
    }

    .card-image {
      height: 200px;
      background-size: cover;
      background-position: center;
    }

    > .card-divider {
      &.header,
      p {
        background: $success-color !important;
        color: $white;
        text-align: center;
        flex-direction: column;
      }

      &.footer {
        background: none;
      }
    }
  }
}
// INFO CARD KÉP NÉLKÜL

.infocard-noimage {
  & h2 > a {
    color: $black !important;
  }

  .info-card {
    color: $white;
    min-height: 230px;
    @include box-shadow-on;

    & a {
      color: $white;
    }

    .card-section {
      border-bottom: none;
      background: none;
    }
  }
}
// NEWS CARD HIREK MULTI
$news-card-label-background: $primary-color;
$news-card-label-background-hover: scale-color($news-card-label-background, $lightness: -15%);

.card-news-image-title-date {
  background-color: $light-shell;

  &.card {
    border-width: 0 0 0.1rem 0;
    border-bottom-color: $success-color;
  }

  a {
    text-decoration: none;
    color: $dark-gray;

    &:hover {
      color: $primary-color;
    }
  }

  .card-section {
    background-color: $light-shell;
    padding: 0 1rem 1rem;
  }

  & h4,
  h5 {
    line-height: 1.2;
    font-weight: bold;
    margin: 15px 0 0; //    font-size: 1rem;

    a {
      color: $primary-color !important;
    }
  }

  .photo-big {
    min-height: 450px;
    max-height: 450px;
    width: 100%;
  }

  .photo-small {
    min-width: 100%;
    @include breakpoint(medium) {
      min-height: 260px;
      max-height: 260px;
    }
    @include breakpoint(large) {
      min-height: 180px;
      max-height: 180px;
    }
  }
}

.card-news-image-background {
  //background-image: url(/assets/img/dummy7.jpg);
  background-size: cover;
  background-position: top center;
}

.card-esemeny-box {
  //    @include box-shadow-on;
  .pixel {
    //    background-image: url('/assets/img/dummy5.jpg');
    height: 180px;
    background-size: cover;
    background-position: center;
  }

  .kerdes {
    display: inline-block;
    @include breakpoint(small) {
      font-size: 0.8rem;
      min-width: 51px;
    }
    @include breakpoint(medium) {
      font-size: 1rem;
      min-width: 65px;
    }
    @include breakpoint(large) {
      font-size: 0.8rem;
      min-width: 51px;
    }
  }

  .valasz {
    font-size: 1rem;
    font-weight: bold;
    @include breakpoint(small) {
      font-size: 0.8rem;
    }
    @include breakpoint(medium) {
      font-size: 1rem;
    }
    @include breakpoint(large) {
      font-size: 0.8rem;
    }
  }

  & .thumbnail {
    margin-top: 0 !important;
  }

  & .heading-primary {
    text-align: left !important;
    @include breakpoint(small) {
      text-align: center;
      margin-top: 1.25rem;
      margin-bottom: 0;
    }
  }
}
