.bhf-header-off-canvas {
  background-color: $secondary-color;

  .menu {
    padding: 1rem 0;

  }

  .menu a {
    color: $white;
  }

}
.menu-icon::after{
  background: $black !important;
}
.off-canvas{
  background: $success-color !important;
  a{
    color: $white;
  }
  & .close-button{
    font-size: 3em !important;
    z-index: 99999 ;
    }
/*    .position-right {
    width: 100%;*/
}
