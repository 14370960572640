.pagination {
  margin-top: 2rem;

  .current {
    padding: 0.2rem 0.8rem !important;
    background: $success-color;
    color: #fff;
    cursor: default;
  }

  & li {
    margin-right: 0.0625rem;
    border-radius: 3px !important;
    font-size: 1.2rem !important;
    display: none;
    @include boder-radius-on;
//    @include box-shadow-on;

  }
  & a, button {
      padding: 0.2rem 0.8rem !important;
      @include boder-radius-on;

  }
}
