.article {
  .lead {
    padding: 2rem 0 0.5rem;
    font-weight: bold;
    color: $deep-gray;
  }

  .article-info {
    //    border: solid #000;
    //    border-width: 0.01rem;
    & i{
      color: $success-color;
      font-size: 1rem;
      margin-right: 2px;
    }
  }
}

.image-featured {
  width: 100%;
}
