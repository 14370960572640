.noo-responsive-schedule-wrap{
    background: $white;
    border: 20px solid $white;
    margin-bottom: 50px;
}
.export-timetable a{
  @include button;
  float: left;
}
.item-weekday{
    font-weight: bold;
}
