// Font weights
$font-extra-bold: 800;
$font-semi-bold: 600;
$font-regular: 400;
$font-light: 300; // Special headings (Feature box, Callout-Cta-Box etc)
$heading-primary-weight: $font-extra-bold;
$heading-secondary-weight: $font-light;
$heading-sub-weight: $font-regular;
$heading-primary-textransform: uppercase;
$heading-secondary-textransform: uppercase;
$heading-sub-textransform: uppercase;
$heading-margin-bottom: 0;
$list-style-position: inner;


body{
  font-size: 1rem;
}

@mixin heading-line-height {
  line-height: 1.4;
}
@mixin heading-primary {
  font-weight: $heading-primary-weight;
//  text-transform: $heading-primary-textransform; //  margin-bottom: $heading-margin-bottom;
  @include heading-line-height;
}
@mixin heading-secondary {
  font-weight: $heading-secondary-weight;
//  text-transform: $heading-secondary-textransform; //  margin-bottom: $heading-margin-bottom;
  @include heading-line-height;
}
// Heading classes

.heading-primary {
  @include heading-primary;
}

.heading-secondary {
  @include heading-secondary;
}

.lead {
  font-family: $header-font-family;
  line-height: 1.4 !important;
}

.small-text {
  font-size: 0.8rem;

  &.date {
    padding: 5px 0;
    align-content: center;
    align-items: center;
    display: flex;
  }

  &-bold {
    font-weight: bold;
  }
}

.blockquote-sign {
  font-size: 4rem;
  float: left;
  line-height: 2rem;
  margin-right: 20px;
  color: $success-color;
}
@mixin media-object-heading-news {
  a {
    color: $black;
  }

  h4,
  h5 {
    //  @include heading-primary;
    margin-bottom: 0;
    font-weight: bold;
  }
}

a.more {
  color: $secondary-color !important;
  font-size: 1rem;
  font-weight: $font-extra-bold;
  text-decoration: dotted;
  text-align: right !important;

  -center {
    text-align: center;
  }
}

.title {
  display: inline-block;
  position: relative;
  text-align: center;
  & .first-word{
    color: $secondary-color !important;
  }

/* &-bg {
    background-image: url("/assets/img/lotus.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
//    opacity: 0.1;
    height: 81px;
    width: 149px;
    position: absolute;
    top: -52px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  } */
}
