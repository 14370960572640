.widget-image-title-text,
.widget-title-text {
  .media-object {
    padding: 1rem 0 0;
    border: solid $medium-gray;
    border-width: 0.01rem 0 0;
    width: 100%;
    flex-wrap: wrap;

    .media-object-section {
      padding-left: 0 !important;
      flex-grow: 1;
      flex-shrink: 1;
      padding-right: 0;

      &.image {
        flex-basis: 90px;
        flex-grow: 1;
        padding-right: 1rem;
      }

      &.text {
        flex-basis: 140px;
        flex-grow: 15;
      }
    }

    .thumbnail {
      border: none;
      box-shadow: none;
      margin-top: 10px;
      background-size: cover;
        @include boder-radius-on;
      img {
        max-width: 100px !important;
        border: none;
        @include box-shadow-on;

      }
    }
  }

  > :last-child {
    border-bottom: 0.01rem solid $medium-gray;
    padding-bottom: 1.3rem;
  }
}

.widget-title-text {}
// ESEMENYEK CARD START

.kapcsolodo-hirek {
  .thumbnail {
//  background: url("/assets/img/square-image.jpg");
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    img{
      @include image-max-height-width;
    }
  }
}

.kapcsolodo-esemenyek {
  background-color: $success-color-light; //  width: 100%;
  border: 0;

  &.nobackground {
    background-color: transparent !important;
  }

  a {
    color: $black;
  }

  .thumbnail {
    border: 0;
    box-shadow: none;

    .calendar_block-day {
      margin-right: 0;
    }
  }
  @include media-object-heading-news;
}
