$card-user-box-margin-bottom: 1rem;
$card-user-box-radius: $global-radius;
$card-user-box-background: $white;
$card-user-box-color: $black;
$card-user-box-image-size: rem-calc(190);

.card-user-box {
  margin-bottom: $card-user-box-margin-bottom; //  background-color: $card-user-box-background;
  color: $black;
  overflow: hidden;
  padding: 0;
  border-radius: $card-user-box-radius;
  background: none !important;
  border: none !important;

  .card-user-bio {
//    text-shadow: 0 1px 25px #000;
    padding: 1rem;
    text-align: center !important;
    color: $white;
    & a{
      color: $white;
    }
  }

  .card-user-avatar {
    background: url("/assets/img/mandala.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center;
//    background-color: $secondary-color;
    border-radius: 50%;
    width: 11.875rem;
    height: 11.875rem;
    margin: auto;

    .user-image {
      display: block;
      margin: auto;
      border-radius: 50%;
      width: $card-user-box-image-size;
      height: $card-user-box-image-size;
      padding: rem-calc(15);
      border: solid rgba(255, 255, 255, 0) 15px;
      transition-duration: 0.5s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
