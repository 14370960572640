.label {
  color: $white !important;
  @include boder-radius-on;
  line-height: 0.7rem !important;

  &.success {
    cursor: inherit;
  }
}
.labels{
  padding-top: 5px;
//  @include flex-align-middle;
}
