$card-oktatoink-box-margin-bottom: 1rem;
$card-oktatoink-box-radius: $global-radius;
$card-oktatoink-box-background: $white;
$card-oktatoink-box-color: $black;
$card-oktatoink-box-image-size: rem-calc(190);

.card-oktatoink-box {
  margin-bottom: $card-oktatoink-box-margin-bottom; //  background-color: $card-oktatoink-box-background;
//  color: $black;
  overflow: hidden;
  padding: 0;
  border-radius: $card-oktatoink-box-radius;
  background: none !important;
  border: none !important;
  color: $white;

  .card-oktatoink-bio {
//    text-shadow: 0 1px 25px #000;
    padding: 1rem;
    color: $white;
    text-align: center !important;
    .small-text{
    font-weight: bold;

    }
  }

    .oktatoink-image {
      display: block;
      margin: auto;
      border-radius: 50%;
      width: $card-oktatoink-box-image-size;
      height: $card-oktatoink-box-image-size;
      padding: rem-calc(15);
      border: solid rgba(255, 255, 255, 0) 15px;
      transition-duration: 0.5s;

      &:hover {
        transform: scale(1.1);
      }
    }

}
