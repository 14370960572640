.footer{
  background: $deep-gray;
  font-size: 12px;
  color: $white;
  .cell{
    border-right: 1px solid ;
    border-color: scale-color($anchor-color, $lightness: -50%);
    &:last-child{
      border-right: 0;
    }
  }
  & h5{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: $heading-primary-weight;
    color: $light-shell;

  }
  & .menu a{
    padding: 0.5rem 0 !important;
    color: $light-gray;
    display: inline-block;
    & :hover{
      color: $success-color;
    }

  }
}
