.block-info {
  @include breakpoint(small) {
    padding: 2rem 1rem 3rem;
  }
  @include breakpoint(medium) {
    padding: 1rem 2rem 2rem;
  }
  /*  @include breakpoint(large) {
    padding: 4rem 3rem 5rem;
  }*/
  @include xy-grid-container(100%, 0);

  & a {
    text-decoration: none;
  }

  & h2 {
    @include heading-secondary; //    margin-bottom: 0;
  }

  &-small {
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;

    img {
      padding-bottom: 0.5rem;
    }
  }
}
//BIG BLOCK BACKGROUNDS

.block-main {
  @include breakpoint(small) {
    background: none;
  }
  @include breakpoint(medium) {
    background: url("/assets/img/fa.png");
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-clip: padding-box;
  }
}

.block-info-success {
  background-color: $sand-color;
  /*  background-image: url("/assets/img/background-studio2.jpg");*/
  background-blend-mode: luminosity;
  background-size: cover;
  background-position: 50% 0;
}

.block-info-info {
  background-color: rgba($primary-color, .35);
}

.block-hirek-multi {}

.block-info-testimonial {
  /*  background: url("/assets/img/background-udaipur.jpg");*/
  background-size: cover;
}

.block-info-oktatoink {
  background: $secondary-color;
  position: relative;
  overflow: hidden;
  color: $white;

  > a {
    color: $white !important;
  }

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1; //    opacity: 0.3;
    //    background-image: url("/assets/img/background-mat.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover; //    background-blend-mode: saturation;
  }

  .cell {
    z-index: 2;
    position: relative;
  }
}

.block-info-orarend-hirek {
  background: $info-color; //  background-image: url('/assets/img/background-devanath.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; //  background-blend-mode: multiply;
  color: $white;

  .esemenyek a {
    color: $white;
  }
}

.block-info-testimonial-esemenyek {
  background: $secondary-color; //  background-image: url("/assets/img/background-devanath.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; //  background-blend-mode: multiply;
  color: $white;
}

.block-video-multi {
  background: $deep-gray;

  p {
    color: $white;
  }
}
