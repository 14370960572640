.calendar_block-day {
  height: 60px;
  min-width: 60px;
  background: #000;
  color: #fff;
  text-align: center;
  font-weight: 900;
  @include boder-radius-on;

  @include breakpoint(small) {
    float: none;
    margin: auto;
    width: 100% !important;
  }
  @include breakpoint(medium) {
    float: left;
    width: 60px !important;
    margin-right: 10px;
  }
  @include breakpoint(large) {
    margin-right: 10px;

    & ul {
      margin-left: $list-side-margin;
    }
  }

  & ul {
    margin: auto;
  }

  & .month {
    font-size: 8px;
    text-transform: uppercase;
    list-style-type: none;
    margin: 0;
    display: block;
    padding: 5px 0 0;
  }

  & .day {
    font-size: 30px;
    line-height: 1;
    list-style-type: none;
  }

  & .day-text {
    font-size: 8px;
    text-transform: uppercase;
    list-style-type: none;
    margin: 0;
    display: block;
    padding: 0;
  }

  &.light {
    background: $white !important;
    color: $black;
  }
}

.kozelgo-esemenyek{
   a{
    color:$black;
  }
}
