.block-video-multi {
  & h2,
  h3 {
    color: $white;
    margin-top: 1rem;
  }
}
$image-hover-wrapper-transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
$image-hover-wrapper-transition-delay: 0.25s;
$image-hover-wrapper-banner-width: 100%;
$image-hover-wrapper-banner-topspace: 28%;
$image-hover-wrapper-banner-bg: rgba($primary-color ,0.8);
$image-hover-wrapper-icon-size: 24px;

.image-hover-wrapper {
  position: relative;
  /* Video Image Big Start */
  .thumb {
//    background-image: url("/assets/img/youtube-image-maxresdefault_1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $black;
    position: relative;

  }

  @include breakpoint(small) {
    width: 100%;
    float: none;
  }

  a {
    display: inline-block;
    letter-spacing: normal;
    line-height: initial;
    margin: 0;
    padding: 0;
    position: relative;
    color: $white;
    text-align: left;
    width: 100%;
    .title-video{
  //    font-size: 1rem;
      font-weight: bold;
      color: $black;
      }

    img {
      float: left;
      overflow: hidden;
      width: 100%; //      max-height: 200px;
      vertical-align: middle;
    }

    & .play-btn {
      width: 38px;
      height: 38px;
      top: calc(50% - 19px);
      left: calc(50% - 19px);
      position: absolute;
      z-index: 3;
      background: url("/assets/img/play-button.svg") no-repeat;
      z-index: 81;
    }

    .image-hover-wrapper-reveal {
      background-color: rgba(0, 0, 0, 0.5);
      color: $white;
      display: inline;
      height: 100%;
      margin-left: -100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 80;
      transition: $image-hover-wrapper-transition;
      transition-delay: $image-hover-wrapper-transition-delay;

      &:hover {
        opacity: 1;
        transition: $image-hover-wrapper-transition;
      }

      &:hover p {
        opacity: 1;
        transform: translateY(-100%);
        transition: $image-hover-wrapper-transition;
        transition-delay: $image-hover-wrapper-transition-delay;
      }

      p {
        bottom: 0;
        display: block;
        opacity: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        transition: $image-hover-wrapper-transition;
      }
    }
  }
  /* Video Image Big Start */
  &.large {
    & .play-btn {
      width: 70px;
      height: 70px;
      top: calc(50% - 29px);
      left: calc(50% - 35px);
      background-color: rgba($black ,0.3);
      border-radius: 100px;
    }

    &.title {
      font-size: 2rem !important;
    }

    &.close-button {
      color: #fff;
      font-size: 4rem;
    }

    img {
      max-height: 180px;
    }
    @include breakpoint(medium) {
      img {
        max-height: 300px;
      }
    }
    @include breakpoint(large) {
      img {
        max-height: 250px;
      }
    }
    @include breakpoint(xlarge) {
      img {
        max-height: 300px;
      }
    }
  }
  /* Video Image Big End */
  /* Video Image Medium Start */
  &.medium {
    img {
      max-height: 200px;
    }
    @include breakpoint(medium) {
      img {
        max-height: 300px;
      }
    }
    @include breakpoint(large) {
      img {
        max-height: 150px;
      }
    }
    @include breakpoint(xlarge) {
      img {
        max-height: 180px;
      }
    }
    @include breakpoint(xxlarge) {
      img {
        max-height: 200px;
      }
    }
  }
    /* Video Image Medium End */
    /* Video Image Small Start */
    &.small {
      img {
        max-height: 100px;
      }
      @include breakpoint(medium down) {
        img {
          max-height: 220px;
        }
      }
      @include breakpoint(large) {
        img {
          max-height: 100px;
        }
      }
    }
    /* Video Image Small End */
}
