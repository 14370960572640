// Borders
$border-color: $light-gray;
$border-width: 0.051rem;

@mixin border-left{
  border-left: $border-color $border-width solid;
}
@mixin horizontal-left-padding-big{
  @include breakpoint(large up) {
    padding-left: 3rem !important;
  }
}
@mixin horizontal-left-padding-big-with-border{
  @include horizontal-left-padding-big;
  @include breakpoint(large up) {
    @include border-left;
  }
}
.padding-left{
   @include horizontal-left-padding-big;
   &-border{
     @include horizontal-left-padding-big-with-border;
   }
}
.padding-right{
  @include breakpoint(medium up) {
    padding-right: 3rem !important;
  }
}
.border-left{
  @include border-left;
}
