// MIXINS
@mixin no-bulleted-list{
  list-style-type: none !important;
  list-style-image: none !important;
  margin-left: 0;
}
// MIXINS
@mixin zero-horizontal-padding{
  padding-right: 0 !important;
  padding-left: 0 !important;
}
/*.grid-container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}
*/
.padding-bottom-zero{
  padding-bottom:0 !important
}
.padding-top-zero{
  padding-top:0 !important
}
$subscription-form-bg: darken($primary-color, 20%);

/* A lekeretitendő objektumok értéke */
@mixin boder-radius-on{
  border-radius: 3px !important;
}
@mixin border-off{
  border: 0 !important;
}
.border-off{
  @include border-off;
}
@mixin box-shadow-on{
  box-shadow: 0 10px 20px rgba($black, .20) !important;
}
.box-shadow-on{
  @include box-shadow-on;
}
@mixin flex-align-middle{
  align-content: center;
  align-items: center;
  display: flex;
}
@mixin image-max-height-width{
  min-width:100%;
  min-height: 100%;
}

// BLOCK LEVEL FORMATING
.top-bar-block, .hero-header-block{
  background: #f9faff;
  @include zero-horizontal-padding;
}
.inner-body{
  background: #f9faff;
  @include zero-horizontal-padding;
}

hr{
  &.thin{
    margin: 0.5rem auto;

  }
}
a{
  transition: color 0.5s ease;
}

.logo-box{
  @include breakpoint(small down) {
    padding-bottom: 2rem;
  }
}
