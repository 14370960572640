.mega-menu {
  .dropdown-pane {
    width: 100%;
//    max-width: 25rem;
    margin: 0 auto !important;
    left: 0% !important;
    right: 0% !important;
    background: $white;
    border: solid $success-color !important;
    border-width:  0 0 5px 0;
    .grid-x{
        flex-wrap: nowrap;
        max-width: 100%;
    }

  }

  .menu {
    background: $white;
    > a::after {
      display: block;
      width: 0;
      height: 0;
      border: inset 6px;
      content: '';
      border-bottom-width: 0;
      border-top-style: solid;
      border-color: $alert-color transparent transparent;
      margin-left: 10px;
      margin-top: 11%;
    }
  }


}
