// SIMPLE SUBSCRIPTION FORM
$subscription-form-bg: darken($primary-color, 20%);
$subscription-form-color: $white;
$subscription-form-padding: rem-calc(40);

.simple-subscription-form {
  //  background: $subscription-form-bg;
  background: $success-color;
  color: $subscription-form-color ; //  padding: $subscription-form-padding;
  border-radius: $global-radius;
  padding-top: 2rem;
  padding-bottom: 0;

  & .input-group, h4, p {
    margin-bottom: 0;
    @include breakpoint(small) {
      display: block;

      .input-group-field {
        margin-top: 10px;
      }
    }
    @include breakpoint(medium) {
      display: flex;

      .input-group-field {
        margin-top: 0;
      }
    }
  }

  & .button {
    margin-bottom: 0;
    border-radius: 0 5px 5px 0 !important;
    background: $warning-color;
    @include breakpoint(small) {
      margin-top: 20px;
      width: 100%;

    }
    @include breakpoint(medium) {
      margin-top: 0;
      width: auto;
    }
  }
  & .terms label{
    color: $white;
    a{
      color: $warning-color;
    }
  }
  & i {
    font-size: 3.5rem;
    line-height: 3.5rem;
    float: right;
  }
}
