
@mixin reveal-background{
  background-color: rgba($black, 0.95) !important;
}
.reveal {
  background: $sand-color !important;

  .close-button {
    color: $white;
    font-size: 4rem;
  }

  .reveal-video-box {
    justify-content: center;
    flex-direction: row;
  }

  &.full {
    justify-content: center;
//    display: flex !important;
    align-items: center;

    .modal-video {
    @include reveal-background
    }

    .reveal-overlay {
      @include reveal-background
    }
  }
}

.reveal-overlay {
  @include reveal-background
}

.modal-video {
  &.reveal {
    @include reveal-background
  //        display: flex !important;
  }

  .reveal-overlay {
    background-color: rgba($black, 0.95) !important;
  }
}
