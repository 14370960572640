/* MEGA MNENU PLUGIN RXTRA CLASSES */
#mega-menu-wrap-layers-primary {
  clear: none;
  float: right;
  width: 100%;
  z-index: 999;

  & .mega-menu-toggle .mega-toggle-block-1:before {
    font-size: 45px;
  }

  #mega-menu-layers-primary {
    float: right;
    @media only screen and (max-width: 1023px) {
      width: 100%;
      position: absolute;
    }
    > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link{
     text-align: left !important;
   }
  }
}
.bhf-header {
  width: 100%;
  background-color: $light-shell !important;
  padding: 1rem 1rem 0;

  .logo {
    height: 60px; //    max-width: 160px;
    margin: 5px 0 4px;
    float: left; //    position: absolute;
    z-index: 9999;
  }

  ul {
    background-color: $black;
  }

  li {
    display: inline-block;
  }

  .nav-links {
    margin-left: 15px;
  }

  a {
    color: $black;
    transition: color 0.5s ease;
    transition: background-color 0.5s ease;
    display: block;
    font-weight: 700;
    /*    text-transform: uppercase;*/
  }

  a:hover {
    //  color: $warning-color;
    transition: color 0.5s ease;
  }

  &.top-bar {
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;

    ul {
      background-color: $light-shell;
    }

    & .top-bar-right {
      & .logo {
        float: left;
        position: absolute;
        height: 40px;
      }
      @include breakpoint(large) {
        margin-right: 60px !important;
      }

      @include breakpoint(xxlarge) {
        margin-right: 0 !important;
      }
      @include breakpoint(medium) {
        width: 100%;
      }
    }
  }

  .menu > .main-nav-link {
    &.active {
      color: $success-color !important;
      border-bottom: 3px solid $success-color;
    }

    > a {
      padding: 1rem 1rem 20px;
      font-size: 0.9rem;

      &.felveteli {
        padding: 0.60em 0.8em; //      margin-top: 0.5rem;
      }
    }
  }

  & .active > a {
    background: none !important;
    color: $success-color !important;
  }
}

.bhf-header-top {
  width: 100%;
  background-color: $black; //  height: 40px;
  padding: 8px 20px 10px;

  .fa {
    color: $black;
    font-size: 1.5rem;
  }
}

.bhf-header-top-message {
  color: lighten($dark-gray, 5%);
  float: left;
}

.bhf-header-top-links {
  color: lighten($dark-gray, 100%);

  & ul,
  li {
    display: inline;
    padding-left: 20px;
    font-size: 0.7rem;
    text-transform: uppercase;
  }

  a {
    color: $white;
    transition: color 0.5s ease;
  }

  a:hover {
    color: lighten($dark-gray, 100%);
    transition: color 0.5s ease;
  }
}

.bhf-header-mobile {
  background-color: $white;
  display: flex;
  padding: rem-calc(10) rem-calc(20);
  align-items: center;
  justify-content: space-between;
}

.bhf-header-mobile-center,
.bhf-header-mobile-left,
.bhf-header-mobile-right {
  flex: 1 0 0;
}

.bhf-header-mobile-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bhf-header-mobile-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bhf-header-mobile-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .fa {
    color: $black;
    font-size: 1.5rem;
  }
}
// HAMBURGER MENU ICON

.menu-icon {
  width: 30px !important;
  height: 16px;
}

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 4px !important;
  background: $success-color !important;
  -webkit-box-shadow: 0 7px 0 $success-color, 0 14px 0 $success-color;
  box-shadow: 0 10px 0 $success-color, 0 20px 0 $success-color !important;
  content: '';
}

.off-canvas-content {
  //    border-bottom: 1px solid #FF6B19;
}
