$light-shell: #f9faff;
//$deep-gray: #2E3532;
$info: #0280A1;
$info-color: $info;
$sand-color:#f3f1ee;
$alert-light:#fff0d5;
$success-color-light:#c2e5e2;
$info-color-light:rgba($info-color, .25);

.success{
  color: $success-color;
}
.primary{
  color: $primary-color;
}
.secondary{
  color: $secondary-color;
}
.warning{
  color: $warning-color;
}
.alert{
  color: $alert-color;
}
.alert-light{
  color: $alert-light;
}
.info{
  color: $info-color;
}
.light-shell{
  color: $light-shell;
}
.sand{
  color:$sand-color;
}
.white{
  color:$white;
}
.black{
  color:$black;
}
.deep-gray{
    color: $deep-gray;
}

.background-primary{
  background-color: $primary-color !important;
}
.background-secondary{
  background-color: $secondary-color !important;
}
.background-warning{
  background-color: $warning-color !important;
}
.background-info{
  background-color: $info-color !important;
}
.background-alert{
  background-color: $alert-color !important;
}
.background-alert-light{
  background-color: $alert-light !important;
}
.background-success{
  background-color: $success-color !important;
}
.background-black{
  background-color: $black !important;
}
.background-white{
  background-color: $white !important;
}
.background-sand{
  background-color: $sand-color !important;
}
.background-light-shell{
  background-color: $light-shell !important;
}
.background-light-gray{
  background-color: $light-gray !important;
}
.background-medium-gray{
  background-color: $medium-gray !important;
}
.background-dark-gray{
  background-color: $dark-gray !important;
}
.background-deep-gray{
  background-color: $deep-gray !important;
}
@mixin background-gradient-bw{
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 39%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 39%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 39%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
// GRADIENT: Secondary to Info
@mixin background-gradient-diagonal-secondary-info{
background-image: rgb(174,56,140); /* Old browsers */
background-image: -moz-linear-gradient(-45deg, rgba(174,56,140,1) 0%, rgba(125,185,232,1) 100%) !important; /* FF3.6-15 */
background-image: -webkit-linear-gradient(-45deg, rgba(174,56,140,1) 0%,rgba(125,185,232,1) 100%) !important; /* Chrome10-25,Safari5.1-6 */
background-image: linear-gradient(135deg, rgba(174,56,140,1) 0%,rgba(125,185,232,1) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
// GRADIENT: Primary to Info
@mixin background-gradient-diagonal-primary-info{
background: -moz-linear-gradient(-45deg, rgb(1,160,136) 0%, rgb(125,185,232) 100%) !important; /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, rgb(1,160,136) 0%,rgb(125,185,232) 100%) !important; /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, rgb(1,160,136) 0%,rgb(125,185,232) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#01a088', endColorstr='#7db9e8',GradientType=1 ) !important; /* IE6-9 fallback on horizontal gradient */
}
// GRADIENT: Warning to Info
@mixin background-gradient-diagonal-warning-info{
background: rgb(238,69,0); /* Old browsers */
background: -moz-linear-gradient(-45deg, rgb(238,69,0) 0%, rgb(125,185,232) 100%) !important; /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, rgb(238,69,0) 0%,rgb(125,185,232) 100%) !important; /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, rgb(238,69,0) 0%,rgb(125,185,232) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ee4500', endColorstr='#7db9e8',GradientType=1 ) !important; /* IE6-9 fallback on horizontal gradient */
}


.background-gradient-bw{
  @include background-gradient-bw;
}
.background-gradient-secondary-info{
  @include background-gradient-diagonal-secondary-info;
}
.background-gradient-primary-info{
  @include background-gradient-diagonal-primary-info;
}
.background-gradient-warning-info{
  @include background-gradient-diagonal-warning-info;
}

//rgba;($success-color, .25);
.success-light{
  background-color: $success-color-light;
}

.info-light{
  background-color: $info-color-light;
}

.text-gradient{
@include background-gradient-diagonal-secondary-info
-webkit-background-clip: text;
background-clip: text;
}
.link-black{
  a{
    color: $black;
  }
}
