.sidebar {
  @include breakpoint(large) {
    margin: 50px 0 0 40px;
    min-width: 270px;

   }
     @include breakpoint(medium-up) {
    min-width: 0;
     }


  a {
    color: $black;
  }
}
.widget{
  margin-bottom: 40px;
  h2, h3 {
//    border-bottom: $success-color solid 1px;
    padding-bottom: 5px;
    margin-bottom: 0;
  }
}
